var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal__category",attrs:{"size":_vm.wrongEmployeesBatch.length ? 'xlg' : 'sm'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Importar empleados")]},proxy:true},{key:"content",fn:function(){return [_c('validation-observer',{staticClass:"form__validation",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"batch__content"},[_c('div',{staticClass:"batch__buttons"},[_c('Button',{attrs:{"type":"submit","variant":"primary","size":"small","href":_vm.downloadLink,"download":"carga-masiva-usuarios.csv"}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"#fff"}}),_vm._v(" Descargar plantilla ")],1),_c('file-input',{attrs:{"isLoading":_vm.isUploadingFile,"data":((_vm.employeesBatch.length) + " empleado(s)")},on:{"uploadFile":function (file) { return _vm.parseFile(file); },"removeFile":function($event){{
                    _vm.wrongEmployeesBatch = [];
                    _vm.employeesBatch = [];
                  }}}}),(_vm.wrongEmployeesBatch.length)?_c('AlertBox',{attrs:{"type":"error"}},[_vm._v(" Los siguientes empleados contienen uno o más campos invalidos, por favor corregirlos para continuar. ")]):(_vm.employeesBatch.length)?_c('AlertBox',{attrs:{"type":"success"}},[_vm._v(" Los empleados estan listos para cargarse. ")]):_vm._e()],1),(_vm.wrongEmployeesBatch.length)?_c('div',{staticClass:"table__container"},[_c('table',[_c('tbody',[_c('tr',[_vm._l((_vm.defaultCategories
                        .filter(function (ref) {
                                          var id = ref.id;

                                          return !['age', 'state'].includes(id);
})
                        .slice(0, -2)),function(defaultCategory){return _c('th',{key:defaultCategory.id},[_vm._v(" "+_vm._s(defaultCategory.name)+" ")])}),_vm._l((_vm.categories),function(category){return _c('th',{key:category.id},[_vm._v(" "+_vm._s(category.name)+" ")])}),_vm._l((_vm.defaultCategories.slice(-2)),function(defaultCategory){return _c('th',{key:defaultCategory.id},[_vm._v(" "+_vm._s(defaultCategory.name)+" ")])})],2),_vm._l((_vm.wrongEmployeesBatch),function(employee,index){return _c('tr',{key:("employee-" + index)},[_vm._l((_vm.defaultCategories
                        .filter(function (ref) {
                                          var id = ref.id;

                                          return !['age', 'state'].includes(id);
                        })
                        .slice(0, -2)),function(defaultCategory){return _c('td',{key:defaultCategory.id},[(defaultCategory.type === 'select')?_c('custom-select',{attrs:{"options":_vm.defaultCategoriesOptions.filter(
                            function (ref) {
                                              var categoryId = ref.categoryId;

                                              return categoryId === defaultCategory.id;
                        }
                          ),"rules":"required"},model:{value:(employee[defaultCategory.id]),callback:function ($$v) {_vm.$set(employee, defaultCategory.id, $$v)},expression:"employee[defaultCategory.id]"}}):_c('custom-input',{attrs:{"type":defaultCategory.id === 'email' ? 'email' : defaultCategory.type,"rules":defaultCategory.id === 'email'
                            ? 'email'
                            : defaultCategory.id === 'terminationDate'
                            ? ("min_date:" + (employee.entryDate))
                            : defaultCategory.id === 'entryDate'
                            ? ("required|min_date:" + (employee.birthDate))
                            : 'required'},model:{value:(employee[defaultCategory.id]),callback:function ($$v) {_vm.$set(employee, defaultCategory.id, $$v)},expression:"employee[defaultCategory.id]"}})],1)}),_vm._l((_vm.categories),function(category){return _c('td',{key:category.id},[_c('custom-select',{attrs:{"options":_vm.allOptions.filter(function (option) { return option.categoryId === category.id; }),"rules":"required"},model:{value:(employee[category.id]),callback:function ($$v) {_vm.$set(employee, category.id, $$v)},expression:"employee[category.id]"}})],1)}),_c('td',[_c('custom-input',{attrs:{"type":"number","rules":'required'},model:{value:(employee.miniumWage),callback:function ($$v) {_vm.$set(employee, "miniumWage", $$v)},expression:"employee.miniumWage"}})],1),_c('td',[_c('custom-input',{attrs:{"type":"number","rules":'required'},model:{value:(employee.otherIncomes),callback:function ($$v) {_vm.$set(employee, "otherIncomes", $$v)},expression:"employee.otherIncomes"}})],1)],2)})],2)])]):_vm._e()]),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"text","size":"small"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('Button',{attrs:{"type":"submit","disabled":invalid || _vm.isLoading || !(_vm.wrongEmployeesBatch.length || _vm.employeesBatch.length),"variant":"primary","size":"small"}},[_vm._v(" "+_vm._s(_vm.isLoading ? 'Cargando...' : 'Cargar empleados')+" ")])],1)])]}}])})]},proxy:true}])}),_c('confirm-dialogue',{ref:"confirmDialogue"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }