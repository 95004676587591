<template>
  <div class="tooltip__container">
    <slot></slot>
    <div class="tip">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tooltip',
  data: () => ({
  }),

  props: {
    text: {
      default: '',
      type: String,
    },
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.tooltip__container {
  position: relative;
  cursor: help;
  &:hover {
    .tip {
      display: block;
    }
  }
  .tip {
    display: none;
    background-color: var(--font-color-700);
    border-radius: 5px;
    width: 10rem;
    text-align: center;
    position: absolute;
    bottom: calc(100% + .5rem);
    left: 50%;
    margin-left: -5rem;
    padding: .4rem .2rem;
    p {
      font-size: .75rem;
      color: white;
      line-height: 1rem;
    }
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
}
</style>
