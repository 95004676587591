<template>
  <div v-show="open" class="alert" :class="alertTypeClass">
    <unicon :name="icon" fill="currentColor" height="16" width="16"></unicon>
    <slot></slot>
    <button type="button" @click="close">
      <unicon name="times" fill="currentColor" height="16" width="16"></unicon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AlertBox',

  data: () => ({
    open: true,
  }),

  props: {
    type: {
      default: 'info',
      type: String,
    },
  },

  computed: {
    alertTypeClass() {
      switch (this.type) {
        case 'info':
          return 'alert--info';
        case 'success':
          return 'alert--success';
        case 'warning':
          return 'alert--warning';
        case 'error':
          return 'alert--error';
        default:
          return 'alert--info';
      }
    },

    icon() {
      switch (this.type) {
        case 'info':
          return 'info-circle';
        case 'success':
          return 'check-circle';
        case 'warning':
          return 'exclamation-triangle';
        case 'error':
          return 'exclamation-octagon';
        default:
          return 'info-circle';
      }
    },
  },

  methods: {
    close() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 0.8rem 1rem 0.8rem 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 0.5rem;
  font-weight: var(--medium);
  background-color: var(--gray-color-100);
  border-left: 3px solid currentColor;
  border-radius: 0.125em;
  font-size: 0.875rem;

  &--info {
    color: var(--main-color-700);
    background-color: var(--main-color-100);
    * {
      color: var(--main-color-700);
    }
  }

  &--success {
    color: var(--success-color-700);
    background-color: var(--success-color-100);
    * {
      color: var(--success-color-700);
    }
  }

  &--warning {
    color: var(--warning-color-700);
    background-color: var(--warning-color-200);
    * {
      color: var(--warning-color-700);
    }
  }

  &--error {
    color: var(--danger-color-700);
    background-color: var(--danger-color-100);
    * {
      color: var(--danger-color-700);
    }
  }
}
</style>
