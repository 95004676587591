var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal__category",attrs:{"size":_vm.wrongEmployeesBatch.length ? 'lg' : 'sm'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Importar actualización")]},proxy:true},{key:"content",fn:function(){return [_c('validation-observer',{staticClass:"form__validation",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"batch__content"},[_c('div',{staticClass:"batch__buttons"},[_c('Button',{attrs:{"type":"submit","variant":"primary","size":"small","href":_vm.downloadLink,"download":"edicion-masiva-usuarios.csv"}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"#fff"}}),_vm._v(" Descargar plantilla ")],1),_c('file-input',{attrs:{"isLoading":_vm.isUploading,"data":((_vm.employeesBatch.length) + " cambios")},on:{"uploadFile":function (file) { return _vm.parseFile(file); },"removeFile":function($event){{
                    _vm.wrongEmployeesBatch = [];
                    _vm.employeesBatch = [];
                  }}}}),(_vm.wrongEmployeesBatch.length)?_c('AlertBox',{attrs:{"type":"error"}},[_c('p',[_vm._v("Los cambios listados son invalidos, corregirlos para continuar.")])]):(_vm.employeesBatch.length)?_c('AlertBox',{attrs:{"type":"success"}},[_c('p',[_vm._v("Los cambios estan listos para cargarse.")])]):_vm._e()],1),(_vm.wrongEmployeesBatch.length)?_c('div',{staticClass:"table__container"},[_c('table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("id")]),_c('th',[_vm._v(_vm._s(_vm.category.name))]),_c('th',[_vm._v("Fecha de cambio")])]),_vm._l((_vm.wrongEmployeesBatch),function(employee,index){return _c('tr',{key:("employee-" + index)},[_c('td',[_c('custom-input',{attrs:{"type":"text","rules":("required|isValidId:" + (_vm.employees.map(function (ref) {
                                    var id = ref.id;

                                    return id;
                  })))},model:{value:(employee.id),callback:function ($$v) {_vm.$set(employee, "id", $$v)},expression:"employee.id"}})],1),_c('td',[_c('custom-input',{attrs:{"type":['miniumWage', 'otherIncomes'].includes(_vm.category.id) ? 'number' : 'text',"rules":'required'},model:{value:(employee[_vm.category.id]),callback:function ($$v) {_vm.$set(employee, _vm.category.id, $$v)},expression:"employee[category.id]"}})],1),_c('td',[_c('custom-input',{attrs:{"type":"date","rules":'required'},model:{value:(employee.date),callback:function ($$v) {_vm.$set(employee, "date", $$v)},expression:"employee.date"}})],1)])})],2)])]):_vm._e()]),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"text","size":"small"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('Button',{attrs:{"type":"submit","disabled":invalid || _vm.isLoading || !(_vm.wrongEmployeesBatch.length || _vm.employeesBatch.length),"variant":"primary","size":"small"}},[_vm._v(" "+_vm._s(_vm.isLoading ? 'Cargando...' : 'Cargar actualización')+" ")])],1)])]}}])})]},proxy:true}])}),_c('confirm-dialogue',{ref:"confirmDialogue"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }