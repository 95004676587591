<template>
  <div>
    <Modal :size="wrongEmployeesBatch.length ? 'xlg' : 'sm'" ref="modal__category">
      <template #title>Importar empleados</template>
      <template #content>
        <validation-observer
          tag="div"
          v-slot="{ handleSubmit, invalid }"
          class="form__validation"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="batch__content">
              <div
                class="batch__buttons"
              >
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  :href="downloadLink"
                  download="carga-masiva-usuarios.csv"
                >
                  <unicon width="16px" height="16px" name="file-download" fill="#fff"></unicon>
                  Descargar plantilla
                </Button>

                <file-input
                  :isLoading="isUploadingFile"
                  @uploadFile="(file) => parseFile(file)"
                  :data="`${employeesBatch.length} empleado(s)`"
                  @removeFile="
                    {
                      wrongEmployeesBatch = [];
                      employeesBatch = [];
                    }
                  "
                />

                <AlertBox v-if="wrongEmployeesBatch.length" type="error">
                  Los siguientes empleados contienen uno o más campos invalidos, por favor
                  corregirlos para continuar.
                </AlertBox>
                <AlertBox v-else-if="employeesBatch.length" type="success">
                  Los empleados estan listos para cargarse.
                </AlertBox>
              </div>
              <div v-if="wrongEmployeesBatch.length" class="table__container">
                <table>
                  <tbody>
                    <tr>
                      <th
                        v-for="defaultCategory in defaultCategories
                          .filter(({ id }) => !['age', 'state'].includes(id))
                          .slice(0, -2)"
                        :key="defaultCategory.id"
                      >
                        {{ defaultCategory.name }}
                      </th>
                      <th v-for="category in categories" :key="category.id">
                        {{ category.name }}
                      </th>
                      <th
                        v-for="defaultCategory in defaultCategories.slice(-2)"
                        :key="defaultCategory.id"
                      >
                        {{ defaultCategory.name }}
                      </th>
                    </tr>
                    <tr v-for="(employee, index) in wrongEmployeesBatch" :key="`employee-${index}`">
                      <td
                        v-for="defaultCategory in defaultCategories
                          .filter(({ id }) => !['age', 'state'].includes(id))
                          .slice(0, -2)"
                          :key="defaultCategory.id"
                      >
                        <custom-select
                          v-if="defaultCategory.type === 'select'"
                          v-model="employee[defaultCategory.id]"
                          :options="
                            defaultCategoriesOptions.filter(
                              ({ categoryId }) => categoryId === defaultCategory.id,
                            )
                          "
                          rules="required"
                        />
                        <custom-input
                          v-else
                          v-model="employee[defaultCategory.id]"
                          :type="defaultCategory.id === 'email' ? 'email' : defaultCategory.type"
                          :rules="
                            defaultCategory.id === 'email'
                              ? 'email'
                              : defaultCategory.id === 'terminationDate'
                              ? `min_date:${employee.entryDate}`
                              : defaultCategory.id === 'entryDate'
                              ? `required|min_date:${employee.birthDate}`
                              : 'required'
                          "
                        />
                      </td>
                      <td v-for="category in categories" :key="category.id">
                        <custom-select
                          v-model="employee[category.id]"
                          :options="
                            allOptions.filter((option) => option.categoryId === category.id)
                          "
                          rules="required"
                        />
                      </td>
                      <td>
                        <custom-input
                          v-model="employee.miniumWage"
                          type="number"
                          :rules="'required'"
                        />
                      </td>
                      <td>
                        <custom-input
                          v-model="employee.otherIncomes"
                          type="number"
                          :rules="'required'"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                :disabled="
                  invalid || isLoading || !(wrongEmployeesBatch.length || employeesBatch.length)
                "
                variant="primary"
                size="small"
              >
                {{ isLoading ? 'Cargando...' : 'Cargar empleados' }}
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { ValidationObserver } from 'vee-validate';
import Papa from 'papaparse';
import Button from '@/components/buttons/Button.vue';
import Modal from '@/components/Modal.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import AlertBox from '@/components/AlertBox.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import FileInput from '@/components/custom/FileInput.vue';
import { DMYToYMD, YMDToDate } from '@/dateFormats';
import DEFAULT_CATEGORIES from '@/defaultCategories';

export default {
  name: 'BatchAddEmployeesModal',
  components: {
    Button,
    Modal,
    ValidationObserver,
    ConfirmDialogue,
    CustomInput,
    CustomSelect,
    AlertBox,
    FileInput,
  },
  data() {
    return {
      icons: {
        csv: faFileCsv,
      },
      isLoading: false,
      employeesBatch: [],
      wrongEmployeesBatch: [],
      DMYToYMD,
      YMDToDate,
      isUploadingFile: false,
      defaultCategories: DEFAULT_CATEGORIES.CATEGORIES,
      defaultCategoriesOptions: DEFAULT_CATEGORIES.OPTIONS,
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('categories', [
      'fetchCategories',
      'addCategory',
      'updateCategory',
      'deleteCategory',
    ]),
    ...mapActions('options', [
      'fetchOptions',
      'addOptionsBatch',
      'updateOptionsBatch',
      'deleteOptionsBatch',
    ]),
    ...mapActions('history', ['addLog']),
    ...mapActions('employees', ['fetchEmployees', 'addEmployeesBatch']),

    buildEmployeesCsv() {
      const matrix = [
        'Nombre',
        'Email',
        'Género',
        'Número de documento',
        'Tipo de documento',
        'Fecha de nacimiento',
        'Fecha de ingreso',
        'Fecha de cese',
        ...this.categories.map((f) => f.name),
        'Sueldo Base',
        'Otros Ingresos',
      ];

      const csvContent = `\uFEFF${matrix}`;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      return URL.createObjectURL(blob);
    },

    getFileExtension(filename) {
      return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
    },

    parseFile(file) {
      if (!file) return;
      this.isUploadingFile = true;

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => {
          switch (header.toLowerCase()) {
            case 'nombre':
              return 'name';
            case 'email':
              return 'email';
            case 'género':
              return 'gender';
            case 'número de documento':
              return 'documentNumber';
            case 'tipo de documento':
              return 'documentType';
            case 'fecha de nacimiento':
              return 'birthDate';
            case 'fecha de ingreso':
              return 'entryDate';
            case 'fecha de cese':
              return 'terminationDate';
            case 'sueldo base':
              return 'miniumWage';
            case 'otros ingresos':
              return 'otherIncomes';
            default:
              return header.trim();
          }
        },
        transform: (value) => value.trim(),

        complete: (results) => {
          if (!results) return;

          const categoriesNames = this.categories.map((category) => category.name);

          const employees = results.data.map(
            ({
              name,
              email,
              gender,
              documentNumber,
              documentType,
              birthDate,
              entryDate,
              terminationDate,
              miniumWage,
              otherIncomes,
              ...categories
            }) => ({
              name,
              email,
              gender,
              documentNumber,
              documentType,
              birthDate: this.DMYToYMD(birthDate),
              entryDate: this.DMYToYMD(entryDate),
              terminationDate: this.DMYToYMD(terminationDate),
              miniumWage,
              otherIncomes,
              // filter out categories names which are not in created categories
              // reduce the categories so the header which represents
              // the category becomes the id of that category
              // in the same reduce the row value which represents the option of the column
              // category converts to the option id if it exists, if not it converts
              // into an empty string
              ...Object.keys(categories)
                .filter((category) => categoriesNames.includes(category))
                .reduce((acc, current) => {
                  const categoryId = this.categories.find(
                    (category) => category.name === current,
                  ).id;
                  const option = this.allOptions
                    .filter((opt) => opt.categoryId === categoryId)
                    .find((opt) => opt.name === categories[current]);
                  const optionId = option ? option.id : '';
                  return {
                    ...acc,
                    [categoryId]: optionId,
                  };
                }, {}),
            }),
          );
          this.employeesBatch = employees;
          this.wrongEmployeesBatch = this.filterEmptyCategoriesEmployee(employees);
          this.isUploadingFile = false;
        },
      });
    },

    filterEmptyCategoriesEmployee(employees) {
      return employees.filter(
        ({
          email, terminationDate, miniumWage, otherIncomes, ...categories
        }) => Object.values(categories).filter((val) => val === '').length || !this.validateEmail(email),
      );
    },

    validateEmail(email) {
      if (email === '') return true;
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },

    removeFile() {
      this.employeesBatch = [];
      this.wrongEmployeesBatch = [];
    },

    getEmployeeHistory(employee) {
      const employeeHistory = {};
      Object.keys(employee).forEach((key) => {
        employeeHistory[key] = {};
        employeeHistory[key][employee.entryDate.getTime() / 1000] = employee[key];
      });
      return employeeHistory;
    },

    getHistoryContent(batch) {
      let content = '';
      batch.forEach((employee) => {
        content += `Nombre: ${employee.name}\nNúmero de documento: ${employee.documentNumber}\n\n`;
      });
      return content;
    },

    async addHistory(content) {
      await this.addLog({
        authorId: this.userProfile.id,
        createdOn: new Date(),
        authorName: this.userProfile.name,
        action: 'Agregar',
        content: `Empleado(s) agregado(s):\n${content}`,
      });
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        const batch = this.employeesBatch.map(
          ({
            terminationDate,
            entryDate,
            birthDate,
            miniumWage,
            otherIncomes,
            ...categories
          }) => ({
            birthDate: this.YMDToDate(birthDate),
            entryDate: this.YMDToDate(entryDate),
            terminationDate: this.YMDToDate(terminationDate),
            miniumWage: parseInt(miniumWage, 10),
            otherIncomes: [parseInt(otherIncomes, 10)],
            ...categories,
          }),
        );

        await this.addEmployeesBatch(
          batch.map((employee) => ({ ...employee, history: this.getEmployeeHistory(employee) })),
        );

        await this.addHistory(this.getHistoryContent(batch));

        this.setAlert({
          state: 'success',
          message: 'Se agregaron los empleados',
        });
      } catch (err) {
        console.log(err);

        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        await this.fetchEmployees();
        this.$emit('complete');
        this.isLoading = false;
        this.close();
      }
    },

    async open() {
      this.$refs.modal__category.open();
    },

    close() {
      this.removeFile();
      this.$refs.modal__category.close();
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.employees.employees,
      allOptions: (state) => state.options.options,
      categories: (state) => state.categories.categories,
      userProfile: (state) => state.userProfile,
    }),

    downloadLink() {
      return this.buildEmployeesCsv();
    },
  },
};
</script>

<style lang="scss" scoped>

table {
  border-collapse: separate;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: none;
  border-spacing: 0;
  font-weight: var(--medium);
  margin-bottom: 0;
  * {
    white-space: nowrap;
  }
  tr:first-child {
    th:first-child {
      border-top-left-radius: 10px;
    }
    th:last-child {
      border-top-right-radius: 10px;
    }
  }
  th {
    background-color: var(--gray-color-100);
  };
  td, th {
    min-width: 13rem;
    text-align: left;
    padding: 1em 2.5em 1em 1em;
    border-right: 1px solid var(--gray-color-500);
    border-bottom: 1px solid var(--gray-color-500);
  }
}

.form__validation {
  height: 100%;
  form {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
}

.batch__content {
  padding: 1.8rem 1.8rem;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: 1rem;

  .batch__buttons {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;

    .input-file {
      margin-bottom: 0;
    }
  }

  .table__container {
    overflow: auto;
  }
}
</style>
