<template>
  <div>
    <Modal size="lg" ref="modal__category">
      <template #title>{{ isEdit ? 'Editar Categoría' : 'Nueva Categoría' }}</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(onAction)">
            <div class="category__modal">
              <span>
                <custom-input
                  v-model="category.name"
                  label="Categoría"
                  type="text"
                  rules="required"
                  placeholder="Nombre de la categoría"
                />
                <checkbox v-model="category.haveParent" label="Tiene un padre" />
                <custom-select
                  v-if="category.haveParent"
                  v-model="category.parentId"
                  :options="
                    categories.map((category) => ({ id: category.id, name: category.name }))
                  "
                  label="Categoría padre"
                  rules="required"
                />
              </span>

              <span class="options__title">
                <span>
                  <h4>Opciones</h4>
                  <tooltip
                    text="El orden definido sera el utilizado cuando se visualicen las opciones."
                  >
                    <unicon
                      width="16px"
                      height="16px"
                      name="info-circle"
                      fill="var(--font-color-700)"
                    ></unicon>
                  </tooltip>
                </span>

                <Menu direction="left">
                  <template #label>
                    <unicon
                      class="ellipsis"
                      name="ellipsis-v"
                      fill="currentColor"
                      height="16.5px"
                      width="16.5px"
                    />
                  </template>
                  <template #options>
                    <menu-item @click="triggerInput">
                      <span class="options__input-file">
                        <unicon
                          width="16px"
                          height="16px"
                          name="file-upload"
                          fill="var(--font-color-700)"
                        ></unicon>
                        Cargar opciones CSV
                        <input
                          ref="options__input-file"
                          type="file"
                          @input="handleInputFile"
                        />
                      </span>
                    </menu-item>
                  </template>
                </Menu>
              </span>
              <div
                class="category__options"
                :class="{ 'category__options--haveParent': category.haveParent }"
              >
                <div class="options__list">
                  <sortable-list
                    v-model="optionsCopy"
                    lockAxis="y"
                    :useDragHandle="true"
                    :lockToContainerEdges="true"
                    :draggedSettlingDuration="0"
                  >
                    <sortable-item
                      class="category__option"
                      v-for="(option, index) in optionsCopy"
                      :key="option.id"
                      :index="index"
                    >
                      <!-- <span><p>{{ option.index + 1 }}.</p></span> -->
                      <custom-input
                        v-model="option.name"
                        :ref="`option-${index}`"
                        :shadow="true"
                        type="text"
                        rules="required"
                        placeholder="Escriba una opción..."
                      />
                      <custom-select
                        v-if="category.parentId && category.haveParent"
                        v-model="option.parentId"
                        :options="
                          parentOptions.map((option) => ({ name: option.name, id: option.id }))
                        "
                        label="Opción padre"
                        rules="required"
                      />
                      <icon-button
                        icon="minus-circle"
                        @click="removeOption(index)"
                        label="remover"
                      />
                    </sortable-item>
                  </sortable-list>
                </div>

                <Button
                  @click="addOption"
                  :disabled="false"
                  size="medium"
                  variant="secondary-text"
                >
                  <unicon width="18px" height="18px" name="plus" fill="#B1B0AE"></unicon>
                  Agregar opción
                </Button>
              </div>
              <div></div>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <span>
                <Button
                  v-if="isEdit && selectedCategory.isDeletable"
                  :disabled="isLoading"
                  type="button"
                  variant="danger"
                  size="small"
                  @click="onDelete"
                >
                  <unicon
                    width="17px"
                    height="17px"
                    name="trash"
                    :fill="isLoading ? '#7e8390' : 'var(--main-color-500)'"
                  ></unicon>
                  {{ !isLoading ? 'Eliminar categoría' : 'Eliminando...' }}
                </Button>
                <Button
                  type="submit"
                  :disabled="invalid || isLoading"
                  variant="primary"
                  size="small"
                >
                  {{
                    !isLoading
                      ? isEdit
                        ? 'Guardar cambios'
                        : 'Crear categoría'
                      : isEdit
                      ? 'Guardando...'
                      : 'Agregando...'
                  }}
                </Button>
              </span>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ValidationObserver } from 'vee-validate';
import Papa from 'papaparse';
import CustomInput from '@/components/custom/CustomInput.vue';
import Button from '@/components/buttons/Button.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import SortableList from '@/components/sortableList/SortableList.vue';
import SortableItem from '@/components/sortableList/SortableItem.vue';
import Checkbox from '@/components/custom/Checkbox.vue';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import Modal from '@/components/Modal.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import Tooltip from '@/components/Tooltip.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';

export default {
  name: 'CategoryModal',
  components: {
    CustomInput,
    Button,
    IconButton,
    SortableList,
    SortableItem,
    Modal,
    Checkbox,
    CustomSelect,
    ValidationObserver,
    ConfirmDialogue,
    Tooltip,
    Menu,
    MenuItem,
  },
  data() {
    return {
      category: {},
      options: [],
      optionsCopy: [],
      optionsToDelete: [],
      icons: {
        plus: faPlus,
      },
      isEdit: false,
      isLoading: false,
      selectedCategory: {},
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('categories', [
      'fetchCategories',
      'addCategory',
      'updateCategory',
      'deleteCategory',
    ]),
    ...mapActions('options', [
      'fetchOptions',
      'addOptionsBatch',
      'updateOptionsBatch',
      'deleteOptionsBatch',
    ]),

    async onDelete() {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Eliminar categoría',
        message:
          '¿Estas seguro que quieres eliminar esta categoría? Eliminar una categoría la removera permanentemente de tu lista, esta acción es irreversible.',
        isDestructive: true,
        okButtonText: 'Si, eliminar',
      });

      if (!ok) return;

      this.isLoading = true;

      try {
        await this.deleteCategory(this.selectedCategory.id);
        await this.deleteOptionsBatch(this.options.map((option) => option.id));

        this.setAlert({
          state: 'success',
          message: 'Categoría eliminada',
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        this.isLoading = false;
        this.close();
      }
    },

    async editCategory() {
      if (
        this.category.name !== this.selectedCategory.name
        || this.category.haveParent !== this.selectedCategory.haveParent
        || this.category.parentId !== this.selectedCategory.parentId
      ) {
        await this.updateCategory({
          ...this.category,
          parentsId: this.getCategoryParents(this.category),
        });
      }
    },

    async editOptions() {
      let optionsToEdit = this.optionsCopy
        .map((option, index) => ({ ...option, index }))
        .filter((option) => {
          const isNewOption = !option.id;
          const ogOption = this.options.find((opt) => opt.id === option.id);
          const isOptionChanged = !isNewOption && (ogOption.name !== option.name
            || ogOption.parentId !== option.parentId || ogOption.index !== option.index);
          return isOptionChanged;
        });
      optionsToEdit = optionsToEdit.map((option) => ({
        ...option,
        parentsId: this.getOptionParents(option),
      }));
      const optionToCreate = this.optionsCopy
        .map((option, index) => ({ ...option, index }))
        .filter((option) => !option.id)
        .map((option) => ({
          ...option,
          categoryId: this.selectedCategory.id,
          parentsId: this.getOptionParents(option),
        }));

      try {
        await this.addOptionsBatch(optionToCreate);
        await this.updateOptionsBatch(optionsToEdit);
        await this.deleteOptionsBatch(this.optionsToDelete);
      } catch (error) {
        console.log(error);
        let message = 'Ocurrió un error, por favor inténtelo nuevamente';
        if (error.code === 'permission-denied') {
          message = 'Acceso denegado. No tienes acceso a estos datos.';
        }
        this.setAlert({
          state: 'error',
          message,
        });
      }
    },

    getOptionParents(option) {
      if (option.parentId) {
        const optionParent = this.allOptions.find((opt) => opt.id === option.parentId);
        return optionParent.parentsId
          ? [...optionParent.parentsId, option.parentId]
          : [option.parentId];
      }
      return [];
    },

    getCategoryParents(category) {
      if (category.parentId && category.haveParent) {
        const categoryParent = this.categories.find((cat) => cat.id === category.parentId);
        return categoryParent.parentsId
          ? [...categoryParent.parentsId, category.parentId]
          : [category.parentId];
      }
      return [];
    },

    async addCategoryAndOptions() {
      let categoryId = '';
      categoryId = await this.addCategory({
        ...this.category,
        parentId: this.category.haveParent ? this.category.haveParent : '',
        isDeletable: true,
        isEditable: true,
        haveParent: this.category.haveParent,
        parentsId: this.getCategoryParents(this.category),
      });

      await this.addOptionsBatch(
        this.optionsCopy.map((option, index) => ({
          ...option,
          index,
          parentsId: this.getOptionParents(option),
          haveParent: !!option.parentId,
          categoryId,
        })),
      );
    },

    async onAction() {
      this.isLoading = true;
      if (this.isEdit) {
        try {
          await this.editCategory();
          await this.editOptions();

          this.setAlert({
            state: 'success',
            message: 'Categoría editada',
          });
        } catch (error) {
          console.log(error);
          this.setAlert({
            state: 'error',
            message: 'Ocurrió un error, por favor inténtelo nuevamente',
          });
        }
      } else {
        try {
          await this.addCategoryAndOptions();
          this.setAlert({
            state: 'success',
            message: 'Categoría creada',
          });
        } catch (error) {
          let message = 'Ocurrió un error, por favor inténtelo nuevamente';
          if (error.code === 'permission-denied') {
            message = 'Acceso denegado. No tienes acceso a estos datos.';
          }
          this.setAlert({
            state: 'error',
            message,
          });
        }
      }
      this.isLoading = false;
      this.close();
    },

    async addOption() {
      await this.optionsCopy.push({ name: '', parentsId: [], parentId: '' });
      this.$refs[`option-${this.optionsCopy.length - 1}`][0].$el.focus();
    },

    removeOption(index) {
      if (this.optionsCopy[index].id) this.optionsToDelete.push(this.optionsCopy[index].id);
      this.optionsCopy.splice(index, 1);
    },

    async open(category) {
      this.$refs.modal__category.open();
      this.selectedCategory = category;
      this.setCategoriesAndOption();
    },

    resetOptions() {
      this.options = [];
      this.optionsCopy = [];
      this.optionsToDelete = [];
    },

    close() {
      this.$refs.modal__category.close();
    },

    setCategoriesAndOption() {
      this.resetOptions();
      if (Object.keys(this.selectedCategory).length) {
        this.category = JSON.parse(JSON.stringify(this.selectedCategory));
        this.isEdit = true;
        this.options = this.allOptions.filter(
          (option) => option.categoryId === this.selectedCategory.id,
        );
        this.optionsCopy = JSON.parse(JSON.stringify(this.options));
      } else {
        this.isEdit = false;
        this.category = { parentId: '', haveParent: false };
      }
    },

    triggerInput() {
      this.$refs['options__input-file'].click();
    },

    handleInputFile(e) {
      const file = e.target.files[0];
      if (file.type !== 'text/csv') {
        this.setAlert({
          state: 'error',
          message: 'El tipo de archivo debe ser CSV',
        });
        return;
      }
      this.parseFile(file);
      this.$refs['options__input-file'].value = '';
    },

    parseFile(file) {
      if (!file) return;
      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,

        complete: (results) => {
          if (!results) return;
          this.parseFileResult = results.data.forEach((arr) => {
            this.optionsCopy.push({
              name: arr[0],
              parentsId: [],
              parentId: arr[1]
                ? this.allOptions
                  .filter(({ categoryId }) => categoryId === this.category.parentId)
                  .find(({ name }) => name === arr[1]).id
                : '',
            });
          });
        },
      });
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.employees.employees,
      allOptions: (state) => state.options.options,
      categories: (state) => state.categories.categories,
    }),

    parentOptions() {
      if (this.category.haveParent && this.category.parentId) {
        return this.allOptions.filter((option) => option.categoryId === this.category.parentId);
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.form__validation {
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
}

.category__modal {
  padding: 1.8rem 1.8rem;
  padding-bottom: 0.5rem;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-flow: column;

  & > span:first-child {
    display: flex;
    flex-flow: column;
    gap: 0.8rem;
  }

  .options__title {
    display: flex;
    justify-content: space-between;
    margin: 0.25rem 0;
    margin-top: 1.5rem;

    span {
      display: flex;
      align-items: center;
      gap: .5rem;
    }

    h4 {
      font-size: .875rem;
      color: var(--font-color-700);
    }

    svg {
      color: var(--font-color-600);
    }

    .options__input-file {
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
  }

  .category__options {
    gap: 0.5rem;
    display: flex;
    flex-flow: column;
    max-height: calc(100% - 35px - 88px);
    padding-top: 0.2rem;

    &--haveParent {
      max-height: calc(100% - 35px - 88px - 0.8rem - 42.95px);
    }
    .options__list {
      padding-right: .7rem;
      flex-grow: 1;
      overflow: auto;
    }
    .category__option {
      margin-top: 1rem;
      &:last-child {
        margin-bottom: .3rem;
      }
    }

    .option {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .button {
      justify-content: left;
    }
  }
}

.modal__content .modal__buttons {
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
</style>
