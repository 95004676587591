<template>
  <div class="pagination__container">
    <span>
      <icon-button
        icon="angle-double-left"
        label="Primera página"
        @click="$emit('goFirstPage')"
        :disabled="page === 1"
      />
      <icon-button
        icon="angle-left"
        label="Anterior"
        @click="$emit('previous')"
        :disabled="page === 1"
      />
    </span>
    <span>
      <p>{{ page }}</p>
    </span>
    <span>
      <icon-button
        icon="angle-right"
        label="Siguiente"
        @click="$emit('next')"
        :disabled="page === pageLimit"
      />
      <icon-button
        icon="angle-double-right"
        label="Ultima página"
        @click="$emit('goLastPage')"
        :disabled="page === pageLimit"
      />
    </span>
  </div>
</template>

<script>
import IconButton from './buttons/IconButton.vue';

export default {
  name: 'Pagination',
  components: {
    IconButton,
  },
  props: {
    page: {
      required: true,
      type: Number,
    },
    pageLimit: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination__container {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  span {
    display: flex;

    p {
      width: 1rem;
      text-align: center;
    }
  }
}
</style>
