<template>
  <div>
    <Modal size="lg" ref="modal__employeeView">
      <template #content>
        <div class="employeeView__modal">
          <div class="employee__header">
            <span
              :class="{
                terminated: getState(selectedEmployee.terminationDate) === 'Cesado',
              }"
            >
              <h3>{{ selectedEmployee.name }}</h3>
              <p>
                <font-awesome-icon
                  v-if="getState(selectedEmployee.terminationDate) === 'Cesado'"
                  :icon="icons.xmark"
                />
                <font-awesome-icon v-else :icon="icons.check" />
                {{ getState(selectedEmployee.terminationDate) }}
              </p>
            </span>
            <p>
              Edad
              {{ timeStampToAge(selectedEmployee.birthDate) }}
              año(s)
              <span style="font-weight: 900">&nbsp;&#183;&nbsp;</span>
              {{ selectedEmployee.gender }}
            </p>

            <div class="employee__labels">
              <span class="employee__label">
                <p>
                  <unicon name="calender" fill="currentColor" height="16px" width="16px" />
                  Ingreso
                  {{ timeStampToDMY(selectedEmployee.entryDate) }}
                </p>
                <p v-if="selectedEmployee.terminationDate">
                  <unicon
                    name="calendar-slash"
                    fill="currentColor"
                    height="16px"
                    width="16px"
                  />Ceso
                  {{ timeStampToDMY(selectedEmployee.terminationDate) }}
                </p>
              </span>

              <span class="employee__label">
                <p class="employee__id">
                  <span class="id__icon">
                    <Hovertext :text="selectedEmployee.documentType">
                      <font-awesome-icon :icon="icons.id" />
                    </Hovertext>
                  </span>
                  {{ selectedEmployee.documentNumber }}
                </p>
                <p>
                  <unicon name="envelope" fill="currentColor" height="16px" width="16px" />
                  {{ selectedEmployee.email }}
                </p>
              </span>
            </div>
          </div>
          <div class="employee__content">
            <div class="employee__tabs">
              <Tabs
                :tabList="[
                  { title: 'Categorias', icon: 'folder-open' },
                  { title: 'Remuneración', icon: 'money-bill' },
                  { title: 'Actualizaciones', icon: 'history' },
                ]"
              >
                <template v-slot:tabPanel-1>
                  <div class="employee__table">
                    <table>
                      <tr>
                        <th>Categoria</th>
                        <th></th>
                      </tr>
                      <tr v-for="category in categories" :key="category.id">
                        <td>
                          {{ category.name }}
                        </td>
                        <td>
                          {{ getOptionName(selectedEmployee[category.id]) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </template>
                <template v-slot:tabPanel-2>
                  <div class="employee__income">
                    <div>
                      <div>
                        <p>Total</p>
                        <span class="income__value income__total">
                          <h3>
                            {{
                              parseInt(selectedEmployee.otherIncomes) + selectedEmployee.miniumWage
                            }}
                          </h3>
                        </span>
                      </div>
                      <div>
                        <p>Sueldo basico</p>
                        <span class="income__value">
                          <h3>
                            {{ selectedEmployee.miniumWage }}
                          </h3>
                        </span>
                      </div>
                    </div>
                    <table>
                      <tr>
                        <th>Otros ingresos</th>
                      </tr>
                      <tr
                        v-for="(income, index) in selectedEmployee.otherIncomesArray"
                        :key="`${income}-${index}`"
                      >
                        <td>
                          <span class="income__value">
                            <p>{{ income }}</p>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </template>
                <template v-slot:tabPanel-3>
                  <div class="employee__table">
                    <table>
                      <tr>
                        <th>Fecha</th>
                        <th>Categoría</th>
                        <th>Cambio</th>
                      </tr>
                      <tr v-for="(log, index) in history" :key="`${log}-${index}`">
                        <td>{{ log.date }}</td>
                        <td>{{ log.category }}</td>
                        <td>
                          {{ (log.change || '').seconds ? timeStampToDMY(log.change) : log.change }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </template>
              </Tabs>
            </div>
          </div>
        </div>
      </template>
      <template #buttons v-if="Object.keys(selectedEmployee)">
        <Button type="button" variant="danger" size="small" @click="onDeleteEmployee">
          <unicon width="16px" height="16px" name="trash" fill="var(--danger-color-400)"></unicon>
          Eliminar empleado
        </Button>
        <Button
          :to="{
            name: 'EditEmployee',
            params: { id: selectedEmployee.id },
          }"
          type="button"
          variant="primary"
          size="small"
        >
          <unicon width="16px" height="16px" name="edit" fill="white"></unicon>
          Editar empleado
        </Button>
      </template>
    </Modal>

    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
  faAddressCard,
  faFolderOpen,
} from '@fortawesome/free-regular-svg-icons';
import Tabs from '@/components/Tabs.vue';
import Button from '@/components/buttons/Button.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import Modal from '@/components/Modal.vue';
import Hovertext from '@/components/Hovertext.vue';
import DEFAULT_CATEGORIES from '@/defaultCategories';
import { timeStampToDMY, timeStampToAge, timeStampToDate } from '@/dateFormats';

export default {
  name: 'EmployeeViewModal',
  components: {
    Tabs,
    Button,
    ConfirmDialogue,
    Modal,
    Hovertext,
  },
  data() {
    return {
      icons: {
        more: faMagnifyingGlassPlus,
        check: faCircleCheck,
        xmark: faCircleXmark,
        id: faAddressCard,
        folder: faFolderOpen,
      },
      timeStampToDMY,
      timeStampToAge,
      timeStampToDate,
      defaultCategories: DEFAULT_CATEGORIES.CATEGORIES,
      defaultCategoriesOptions: DEFAULT_CATEGORIES.OPTIONS,
    };
  },
  props: {
    selectedEmployee: {
      type: Object,
    },
  },

  methods: {
    ...mapActions('employees', ['deleteEmployee']),
    ...mapActions('history', ['addLog']),
    ...mapMutations(['setAlert']),

    getState(timeStamp) {
      if (!timeStamp || !timeStamp.seconds) return 'Activo';
      return new Date() > this.timeStampToDate(timeStamp) ? 'Cesado' : 'Activo';
    },

    getOptionName(id) {
      const option = this.options.find((opt) => opt.id === id);
      return option ? option.name : '';
    },

    getHistoryContent() {
      let content = '';
      content += `Nombre: ${this.selectedEmployee.name}\nNúmero de documento: ${this.selectedEmployee.documentNumber}\n\n`;
      return content;
    },

    async addHistory(content) {
      await this.addLog({
        authorId: '32456780098',
        createdOn: new Date(),
        authorName: 'Porras',
        action: 'Eliminar',
        content: `Empleado(s) eliminado(s):\n${content}`,
      });
    },

    async onDeleteEmployee() {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Eliminar empleado',
        message:
          '¿Estas seguro que quieres eliminar a un empleado? Eliminar a un empleado lo removera permanentemente de tu lista, esta acción es irreversible.',
        isDestructive: true,
        okButtonText: 'Si, eliminar',
      });

      if (!ok) return;

      this.isDeleteLoading = true;

      try {
        const content = this.getHistoryContent();
        await this.deleteEmployee(this.selectedEmployee.id);
        this.setAlert({
          state: 'success',
          message: 'Empleado eliminado',
        });
        this.addHistory(content);
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        this.isDeleteLoading = false;
      }
    },

    open() {
      this.$refs.modal__employeeView.open();
    },
  },

  computed: {
    ...mapState({
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),

    history() {
      const history = [];
      Object.keys(this.selectedEmployee.history).forEach((categoryId) => {
        Object.keys(this.selectedEmployee.history[categoryId]).forEach((date) => {
          const category = [...this.categories, ...this.defaultCategories].find(
            ({ id }) => id === categoryId,
          );
          const option = [...this.options, ...this.defaultCategoriesOptions].find(
            ({ id }) => this.selectedEmployee.history[categoryId][date] === id,
          );
          history.push({
            category: category ? category.name : categoryId,
            date: this.timeStampToDMY({ seconds: date }),
            change: option ? option.name : this.selectedEmployee.history[categoryId][date],
          });
        });
      });
      return history;
    },
  },
};
</script>

<style lang="scss" scoped>
.employeeView__modal {
  display: flex;
  flex-flow: column;
  gap: 0.9rem;
  height: 100%;
  padding-top: 2.3rem;

  & > * {
    padding-right: 2.3rem;
    padding-left: 2.3rem;
  }
  .employee__header {
    .employee__id {
      .id__icon {
        cursor: help;
      }
    }
    .employee__labels {
      display: flex;
      flex-flow: column;
      gap: 0.5rem;
      margin-top: 0.9rem;
    }
    .employee__label {
      display: flex;
      gap: 0.5rem;
      p {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background-color: var(--gray-color-100);
        padding: 0 0.5rem;
        width: fit-content;
        border-radius: 5px;
      }
    }
    & > span {
      display: flex;
      align-items: center;
      gap: 1rem;
      p {
        color: var(--success-color-500);
        font-size: 0.85rem;
      }
      &.terminated p {
        color: var(--danger-color-400);
      }

      h3 {
        font-size: 1.6rem;
        display: flex;
      }

      & ~ p {
        font-size: 0.85rem;
      }
    }
  }

  .employee__content {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    flex-grow: 1;
    padding: 0;
    overflow: auto;
    & > * {
      padding-right: 2.3rem;
      padding-left: 2.3rem;
    }

    .employee__income {
      width: 100%;
      & > div {
        display: flex;
        gap: 5rem;
        padding-top: 0.7rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-top: solid 2px var(--gray-color-200);
        border-bottom: solid 2px var(--gray-color-200);
      }

      table {
        width: fit-content;
      }

      .income__total p {
        width: fit-content;
        font-size: 2rem;
      }

      .income__value {
        display: grid;
        width: fit-content;
      }
    }

    .employee__tabs {
      flex-grow: 1;
      overflow: hidden;
    }

    .employee__table {
      height: 100%;
      overflow-y: auto;
      table {
        width: 100%;
        td {
          white-space: pre-wrap;
        }
      }
    }
  }
}
</style>
