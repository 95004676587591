<template>
  <div>
    <Modal :size="wrongEmployeesBatch.length ? 'lg' : 'sm'" ref="modal__category">
      <template #title>Importar actualización</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="batch__content">
              <div
                class="batch__buttons"
              >
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  :href="downloadLink"
                  download="edicion-masiva-usuarios.csv"
                >
                  <unicon width="16px" height="16px" name="file-download" fill="#fff"></unicon>
                  Descargar plantilla
                </Button>

                <file-input
                  :isLoading="isUploading"
                  @uploadFile="(file) => parseFile(file)"
                  :data="`${employeesBatch.length} cambios`"
                  @removeFile="
                    {
                      wrongEmployeesBatch = [];
                      employeesBatch = [];
                    }
                  "
                />

                <AlertBox v-if="wrongEmployeesBatch.length" type="error">
                  <p>Los cambios listados son invalidos, corregirlos para continuar.</p>
                </AlertBox>
                <AlertBox v-else-if="employeesBatch.length" type="success">
                  <p>Los cambios estan listos para cargarse.</p>
                </AlertBox>
              </div>
              <div v-if="wrongEmployeesBatch.length" class="table__container">
                <table>
                  <tbody>
                    <tr>
                      <th>id</th>
                      <th>{{ category.name }}</th>
                      <th>Fecha de cambio</th>
                    </tr>
                    <tr v-for="(employee, index) in wrongEmployeesBatch" :key="`employee-${index}`">
                      <td>
                        <custom-input
                          v-model="employee.id"
                          type="text"
                          :rules="`required|isValidId:${employees.map(({ id }) => id)}`"
                        />
                      </td>
                      <td>
                        <custom-input
                          v-model="employee[category.id]"
                          :type="
                            ['miniumWage', 'otherIncomes'].includes(category.id) ? 'number' : 'text'
                          "
                          :rules="'required'"
                        />
                      </td>
                      <td>
                        <custom-input
                          v-model="employee.date"
                          type="date"
                          :rules="'required'"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                :disabled="
                  invalid || isLoading || !(wrongEmployeesBatch.length || employeesBatch.length)
                "
                variant="primary"
                size="small"
              >
                {{ isLoading ? 'Cargando...' : 'Cargar actualización' }}
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { ValidationObserver } from 'vee-validate';
import Papa from 'papaparse';
import Button from '@/components/buttons/Button.vue';
import Modal from '@/components/Modal.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import AlertBox from '@/components/AlertBox.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import FileInput from '@/components/custom/FileInput.vue';
import { DMYToYMD, YMDToDate, YMDToTimeStamp } from '@/dateFormats';

export default {
  name: 'BatchEditEmployeesModal',
  components: {
    Button,
    Modal,
    ValidationObserver,
    ConfirmDialogue,
    CustomInput,
    AlertBox,
    FileInput,
  },
  data() {
    return {
      icons: {
        csv: faFileCsv,
      },
      isLoading: false,
      isUploading: false,
      employeesBatch: [],
      wrongEmployeesBatch: [],
      DMYToYMD,
      YMDToDate,
      YMDToTimeStamp,
      category: {},
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('history', ['addLog']),
    ...mapActions('employees', ['fetchEmployees', 'updateEmployeesBatch']),

    buildEmployeesCsv() {
      const matrix = ['id', this.category.name, 'Fecha de cambio'];

      const csvContent = `\uFEFF${matrix}`;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      return URL.createObjectURL(blob);
    },

    getFileExtension(filename) {
      return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
    },

    parseFile(file) {
      this.isUploading = true;
      if (!file) return;

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => {
          switch (header.toLowerCase()) {
            case 'id':
              return 'id';
            case 'fecha de cambio':
              return 'date';
            default:
              return this.category.id;
          }
        },
        transform: (value) => value.trim(),

        complete: (results) => {
          if (!results) return;
          console.log(results.data);
          const employees = results.data.map(({ id, date, ...categories }) => ({
            id,
            date: this.DMYToYMD(date),
            // Convert String from excel to float
            ...Object.keys(categories)
              .filter((category) => category === this.category.id)
              .reduce((acc, current) => {
                let value = categories[current];
                if (['miniumWage', 'otherIncomes'].includes(this.category.id)) {
                  value = parseFloat(categories[current]);
                }
                return {
                  ...acc,
                  [current]: value,
                };
              }, {}),
          }));
          this.employeesBatch = employees;
          this.wrongEmployeesBatch = this.filterInvalidEmployee(employees);

          this.isUploading = false;
        },
      });
    },

    filterInvalidEmployee(employeesChange) {
      console.log(employeesChange);
      return employeesChange.filter(
        (changes) => !this.employees.map(({ id }) => id).includes(changes.id)
          || !changes[this.category.id]
          || !changes.date,
      );
    },

    removeFile() {
      this.employeesBatch = [];
      this.wrongEmployeesBatch = [];
    },

    getHistoryContent(batch, prevBatch) {
      let content = '';
      const mergeBatch = [];
      batch.forEach((employee) => {
        const mergeResult = { ...employee };
        const prevEmployee = prevBatch.find(({ id }) => employee.id === id);
        mergeResult.name = prevEmployee.name;
        mergeResult.documentNumber = prevEmployee.documentNumber;
        mergeResult.prevValue = prevEmployee[this.category.id];
        mergeBatch.push(mergeResult);
      });
      console.log('MergeBatch', mergeBatch);
      mergeBatch.forEach((employee) => {
        content += `Nombre: ${employee.name}\nNúmero de documento: ${employee.documentNumber}\n${
          this.category.name
        }: ${employee.prevValue} --> ${employee[this.category.id]}\n\n`;
      });
      return content;
    },

    async addHistory(content) {
      await this.addLog({
        authorId: this.userProfile.id,
        createdOn: new Date(),
        authorName: this.userProfile.name,
        action: 'Actualizar',
        content: `Empleado(s) actualizados(s):\n${content}`,
      });
    },

    updateEmployeeHistory(history, value, timeStamp) {
      const employeeHistory = { ...history };
      employeeHistory[this.category.id][timeStamp.seconds] = value;
      return employeeHistory;
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        if (this.category.id === 'otherIncomes') {
          this.employeesBatch.forEach((employee) => {
            Object.assign(employee, { otherIncomes: [employee.otherIncomes] });
          });
        }
        const batchIds = this.employeesBatch.map(({ id }) => id);
        const employeesToUpdate = this.employees.filter(({ id }) => batchIds.includes(id));
        const content = this.getHistoryContent(this.employeesBatch, employeesToUpdate);
        await this.updateEmployeesBatch(
          this.employeesBatch.map((employee) => ({
            id: employee.id,
            [this.category.id]: employee[this.category.id],
            history: this.updateEmployeeHistory(
              employeesToUpdate.find(({ id }) => id === employee.id).history,
              employee[this.category.id],
              this.YMDToTimeStamp(employee.date),
            ),
          })),
        );
        await this.addHistory(content);

        this.setAlert({
          state: 'success',
          message: 'Se editaron los empleados',
        });
      } catch (err) {
        console.log(err);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        await this.fetchEmployees();
        this.$emit('complete');
        this.isLoading = false;
        this.close();
      }
    },

    async open(category) {
      if (this.category.id !== category.id) this.removeFile();
      this.category = category;
      this.$refs.modal__category.open();
    },

    close() {
      this.removeFile();
      this.$refs.modal__category.close();
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.employees.employees,
      allOptions: (state) => state.options.options,
      categories: (state) => state.categories.categories,
      userProfile: (state) => state.userProfile,
    }),

    downloadLink() {
      return this.buildEmployeesCsv();
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: none;
  border-spacing: 0;
  font-weight: var(--medium);
  margin-bottom: 0;
  * {
    white-space: nowrap;
  }
  tr:first-child {
    th:first-child {
      border-top-left-radius: 10px;
    }
    th:last-child {
      border-top-right-radius: 10px;
    }
  }
  th {
    background-color: var(--gray-color-100);
  }
  td,
  th {
    min-width: 10rem;
    text-align: left;
    padding: 1em 2.5em 1em 1em;
    border-right: 1px solid var(--gray-color-500);
    border-bottom: 1px solid var(--gray-color-500);
  }
}

.form__validation {
  height: 100%;
  form {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
}

.batch__content {
  padding: 1.8rem 1.8rem;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: 1rem;

  .batch__buttons {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;

    .input-file {
      margin-bottom: 0;
    }
  }

  .table__container {
    overflow: auto;
  }
}
</style>
